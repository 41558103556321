@import url('https://fonts.googleapis.com/css2?family=PT+Mono&family=Unbounded:wght@400;700;900&display=swap');

body {
  text-align: center;
}

h1 {
  color: hotpink;
  font-family: 'PT Mono', monospace, sans-serif;
  font-size: 55px;
}

p {
  font-family: 'PT Mono', monospace, sans-serif;
  line-height: 1.5em;
}

a {
  color: hotpink;
}

.emoji-container {
  margin-bottom: 60px;
}

.emoji-card {
  font-family: 'PT Mono', monospace, sans-serif;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
}

.emoji {
  font-size: 24px;
}

.name {
  font-family: 'PT Mono', monospace, sans-serif;
  font-size: 16px;
  margin-top: 5px;
}

.link {
  font-family: 'PT Mono', monospace, sans-serif;
  font-size: 14px;
  color: hotpink
  text-decoration: underline;
}

.email-form {
  background-color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 10px;
}

input[type="email"] {
  font-family: 'PT Mono', monospace, sans-serif;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

button[type="submit"] {
  font-family: 'PT Mono', monospace, sans-serif;
  background-color: hotpink;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}


.hidden {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  font-family: 'PT Mono', monospace, sans-serif;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: opacity 0.5s ease-in-out; /* Adjust the duration here */
}

.visible {
  opacity: 1;
  pointer-events: auto;
}

.hide {
  display: none;
}

.loader {
  margin-top: 100px;
  font-size: 48px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 40px;
  }
}


.hide-bottom-div {
  display: none;
}