@import "https://fonts.googleapis.com/css2?family=PT+Mono&family=Unbounded:wght@400;700;900&display=swap";
body {
  text-align: center;
}

h1 {
  color: #ff69b4;
  font-family: PT Mono, monospace, sans-serif;
  font-size: 55px;
}

p {
  font-family: PT Mono, monospace, sans-serif;
  line-height: 1.5em;
}

a {
  color: #ff69b4;
}

.emoji-container {
  margin-bottom: 60px;
}

.emoji-card {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  font-family: PT Mono, monospace, sans-serif;
  display: inline-block;
}

.emoji {
  font-size: 24px;
}

.name {
  margin-top: 5px;
  font-family: PT Mono, monospace, sans-serif;
  font-size: 16px;
}

.link {
  color: hotpink text-decoration: underline;
  font-family: PT Mono, monospace, sans-serif;
  font-size: 14px;
}

.email-form {
  max-width: 360px;
  background-color: #fff;
  align-items: center;
  margin: 0 auto 10px;
  padding: 10px;
  display: flex;
}

input[type="email"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  font-family: PT Mono, monospace, sans-serif;
}

button[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #ff69b4;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: PT Mono, monospace, sans-serif;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: PT Mono, monospace, sans-serif;
  transition: opacity .5s ease-in-out;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px #0000004d;
}

.visible {
  opacity: 1;
  pointer-events: auto;
}

.hide {
  display: none;
}

.loader {
  margin-top: 100px;
  font-size: 48px;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 40px;
  }
}

.hide-bottom-div {
  display: none;
}

/*# sourceMappingURL=index.f37dd8a7.css.map */
